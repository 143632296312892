import React, { ReactNode } from 'react';

type FormWrapperProps = { children: ReactNode };

export function FormWrapper({ children }: Readonly<FormWrapperProps>) {
  return (
    /**
     * TODO update colors to match the global scss
     */
    <div className="modal-container ">
      <div className=" modal-card rounded-lg m-auto bg-[#48485C]  p-[1px] shadow-lg shadow-[#313139]">
        <div className="bg-[#313139] pt-6 rounded-lg">
          <div className="flex justify-center mb-8" >
            <img
              src="/logo/RemotoPB1line.png"
              alt="My Image"
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
