import React, { useContext, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowDropdown } from '../../../components-library/index.module';
import { leavePayload } from 'constants/';
import { UserContext, SocketContext, InterfaceContext, StreamContext } from 'context/';
import styles from './styles.module.scss';
import {selectMicrophone, selectMuteMicrophone, selectSpeaker, selectMuteCamera, selectCamera} from 'utils/zegoCloud';
import {correlationId} from 'constants/index';
import VolumeControl from 'ui/components-library/VolumeControl';
import AudioLevelMeter from 'ui/components-library/AudioLevelMeter_single';
import AudioMeterUI from 'ui/components-library/AudioMeter';
import CombinedAudioLevelMeter from 'ui/components-library/CombinedLevelMeter';
export default function StreamNavigation() {
  const { toggleListOfParticipants, isListOfParticipants } =
    useContext(InterfaceContext);
  const { remoteStreams, setRemoteStreams, setCallOutputGain, setCallOutputLevel, callOutputGain, callOutputLevel, setStreamOutputGain, setStreamOutputLevel, streamOutputGain, streamOutputLevel,
    roomId, localVideoRef, muteCamera, setMuteCallOutput, setMuteStreamOutput, muteStreamOutput, microphoneDevices, speakerDevices, cameraDevices, zegoCloudCred, muteCallOutput, audioElement, muteMicrophone, speakerDeviceSelected, setSpeakerDeviceSelected, setMuteMicrophone, setMuteCamera, setCameraDeviceSelected, localStream } =
    useContext(StreamContext);
    const audioElementRef = useRef<HTMLAudioElement | null>(null);
    const { socketData } = useContext(SocketContext);
  const {userId} = useContext(UserContext);
  const navigate = useNavigate();
  const remoteStreamRef = useRef<any>(null);
  const toggleParticipants = () => {
    toggleListOfParticipants(!isListOfParticipants);
  };

  const selectMicrophoneLoc = (deviceId: string) => {
    const { zg } = zegoCloudCred;
    selectMicrophone(deviceId, localStream, zg)
  };

  const selectSpeakerLoc = (deviceId: string) => {
    const { zg } = zegoCloudCred;
    setSpeakerDeviceSelected(deviceId)
    
    //selectSpeaker(deviceId, audioElement, zg)
  }

  const selectCameraLoc = (deviceId: string) => {
    const { zg } = zegoCloudCred;
    setCameraDeviceSelected(deviceId)
    selectCamera(deviceId, localStream, zg)
  }

  const selectMuteMicrophoneLoc = () => {
    const { zg } = zegoCloudCred;
    setMuteMicrophone(!muteMicrophone)
    selectMuteMicrophone(!muteMicrophone, zg)

    const muteMicPayload = {
      correlationId: correlationId,
      type: 'SELF_UPDATE_USER_AUDIO_IO',
      usersAudioIOState: {
        muted: !muteMicrophone
      },
    }

    socketData.send(JSON.stringify(muteMicPayload));
  };

  const leaveSession = () => {
    // zegoCloudCred.zg.stopPublishingStream(`stream-${userId}`)
    // zegoCloudCred.zg.logoutRoom(roomId);
    // zegoCloudCred.zg.destroyEngine();
    if (socketData) socketData.send(JSON.stringify(leavePayload));
    navigate('/logout');
  };
  useEffect (()=>{
    if (audioElement){
      audioElementRef.current = audioElement
    }
  }
  , [audioElement])
  
  useEffect (()=>{
   if (remoteStreams.length > 0) {
      remoteStreamRef.current = remoteStreams
    }
    console.log("RMTS", remoteStreams.map(stream => ({ type: typeof stream, isMediaStream: stream instanceof MediaStream })));

  }, [remoteStreams])

  const selectMuteCameraLoc = () => {
    const { zg } = zegoCloudCred;
    setMuteCamera(!muteCamera)
    selectMuteCamera(!muteCamera, localStream, zg)
  }

  const VolumeMeter = ({ volume }: { volume: number }) => {
    return <div style={{ position: 'absolute', width: `${volume}%`, backgroundColor: 'green', height: '20px' }} />;
  };

  const selectMuteCallOutputLoc = () => {
    setMuteCallOutput(!muteCallOutput)
    const muteSpkPayload = {
      correlationId: correlationId,
      type: 'SELF_UPDATE_USER_AUDIO_IO',
      usersAudioIOState: {
        userIds: [userId],
        deafened: !muteCallOutput
      },
    }

    socketData.send(JSON.stringify(muteSpkPayload));
  }

  const selectMuteStreamOutputLoc = () => {
    setMuteStreamOutput(!muteStreamOutput)

  }
  const handleSetCallOutputGain = useCallback((event) => {
    const newGain = parseFloat(event.target.value);
    setCallOutputGain(newGain);  // Update context
  
    // Dynamically modify the audioElement volume
    // if (audioElement) {
    //   audioElement.volume = newGain;  // Set the volume directly on the audio element
    // }
  }, [setCallOutputGain]);
  
  // Modify Stream Output Gain
  const handleSetStreamOutputGain = useCallback((event) => {
    const newGain = parseFloat(event.target.value);
    setStreamOutputGain(newGain);  // Update context
  
    // Dynamically modify the audioElement volume
    if (audioElement) {
      audioElement.volume = newGain;  // Set the volume directly on the audio element
    }
  }, [setStreamOutputGain, audioElement]);

  const handleSpacebar = () => {
    console.log('Spacebar pressed');
    const { zg } = zegoCloudCred;
    setMuteMicrophone(!muteMicrophone)
    selectMuteMicrophone(!muteMicrophone, zg)
    
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the pressed key is the spacebar
      if (event.code === 'Space') {
        handleSpacebar();
      }
    };

    // Add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [muteMicrophone])




  return (
    <div className="session-toolbar">
      <div className="session-toolbar-container">
        <div
          id="fullBtn"
          className="flex flex-row mr-4 divide-x divide-slate-400/25"
        >
          <button
              className="flex items-center bg-[#313139] px-4 py-2 h-full rounded-l-lg hover:cursor-pointer"
              onClick={selectMuteCameraLoc}
          >
            {!muteCamera ?
                <img
                    src="/icons/ic_CameraLine_XS_default.svg"
                    alt="fullscreen"
                    width={20}
                    height={20}
                /> :
                <img
                    src="/icons/ic_CameraLine_XS_pressed.svg"
                    alt="fullscreen"
                    width={20}
                    height={20}
                    
                />
            }
          </button>
          <div className="flex align-center bg-[#313139] px-2 py-2 h-full rounded-r-lg">
            <ArrowDropdown
                dataDropdown={cameraDevices}
                handle={selectCameraLoc}
            />
          </div>
        </div>
        <div
          id="fullBtn"
          className="flex flex-row mr-4 divide-x divide-slate-400/25"
        >
          <button
            className="flex items-center bg-[#313139] px-4 py-2 h-full rounded-l-lg hover:cursor-pointer"
            onClick={selectMuteMicrophoneLoc}
          >
            {muteMicrophone ?
              <img
                src="/icons/micOff.svg"
                alt="fullscreen"
                width={20}
                height={20}
              /> :
              <img
                src="/icons/micOn.svg"
                alt="fullscreen"
                width={20}
                height={20}
              />
            }
          </button>
          <div className="flex align-center bg-[#313139] px-2 py-2 h-full rounded-r-lg">
            <ArrowDropdown
              dataDropdown={microphoneDevices}
              handle={selectMicrophoneLoc}
            />
          </div>
        </div>
        <div
          id="fullBtn"
          className="flex flex-row mr-4 divide-x divide-slate-400/25"
          style = {{backgroundColor: '#313139', borderRadius: '15px'}}
        >
           <button
            className="flex items-center bg-[#313139] px-4 py-2 h-full rounded-l-lg hover:cursor-pointer"
            onClick={selectMuteCallOutputLoc}
          >
            {!muteCallOutput ? <img
              src="/icons/callAudio.svg"
              alt="fullscreen"
              width={20}
              height={20}
            />:
            <img
            src="/icons/callAudioMuted.svg"
            alt="fullscreen"
            width={20}
            height={20}
        
            
            />}
          </button>
          <div style = {{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}> 
          
              <input className = {styles.gainSlider} type="range" 
              id = 'gain_slider_call' 
              min={0}
              max={1}
              step={.01}
              value={callOutputGain} onChange = {handleSetCallOutputGain} />
              {/* {remoteStreamRef.current && <>
      <CombinedAudioLevelMeter remoteStreams={remoteStreamRef.current}  /> </>} */}
              </div>
             <button
            className="flex items-center bg-[#313139] px-4 py-2 h-full  hover:cursor-pointer"
            onClick={selectMuteStreamOutputLoc}
          >
            
            {!muteStreamOutput ? <img
              src="/icons/streamAudio.svg"
              alt="fullscreen"
              width={20}
              height={20}
            />:
            <img
            src="/icons/streamAudioMuted.svg"
            alt="fullscreen"
            width={20}
            height={20}
        
            
            />}
          </button>
          <div style = {{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}> 
          
          <input id = 'gain_slider_stream' className = {styles.gainSlider}
           min={0}
           max={1}
           step={.01}
          type="range" value={streamOutputGain} onChange = {handleSetStreamOutputGain} />
        {/* <VolumeControl /> */}
              </div>
          <div className="flex align-center bg-[#313139] px-2 py-2 h-full rounded-r-lg">
            {<ArrowDropdown
              dataDropdown={speakerDevices?speakerDevices:[{deviceName: "System Default"}]}
              handle={selectSpeakerLoc}
            />}
          </div>

        </div>
        
        {/* <button id="muteBtn" className='mr-4'>
                    <img src="/icons/headphones.svg" alt="fullscreen" width={20} height={20} />
                </button> */}
        <div
          id="muteBtn"
          className={`px-2 py-2 rounded-md border-[#F44336] border ${styles.leaveBtnBg} hover:cursor-pointer`}
        >
          <button className="text-[#F44336]" onClick={() => leaveSession()}>
            Leave Session
          </button>
        </div>
      </div>
      <button
        className="participants-button"
        onClick={() => toggleParticipants()}
      >
        <img
          src="/icons/participants1.svg"
          alt="participants"
          width={24}
          height={17}
        />
      </button>
      {/* {audioElementRef.current && <div style = {{ height: '100%', width: 'stretch', paddingLeft: '20px', textAnchor: 'middle', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px'}} > Stream Audio:
      <AudioLevelMeter stream={audioElementRef.current.srcObject as MediaStream} /> </div>} */}

    </div>
  );
}